import React, { Component } from 'react'
import { graphql } from 'gatsby';
import { getImage, withArtDirection, StaticImage, GatsbyImage } from 'gatsby-plugin-image'

import Layout from '@components/layout/Layout'
import ReferenceComponent from '@components/references/reference.component'
import SiteLink from '@components/SiteLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import LeavingSiteModal from '@components/leaving-site-modal/leaving-site-modal.component'

import { references } from '@components/references/risk-references'

import { liveRampEventTracking } from '../../../app-utils'
import { trackGa } from '@components/GoogleTracking'

import './risk.scss'


class Risk extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      showLeavingSiteModal: false,
      href: '',
    }

    this.handleShowModalClicked = this.handleShowModalClicked.bind(this)
    this.handleHideModal = this.handleHideModal.bind(this)
    this.openLink = this.openLink.bind(this)

    this.infographicModifiableRiskFactorsImg = withArtDirection (
		getImage(props.data.infographicModifiableRiskFactors), [
		{
			media: "(max-width: 750px)",
			image: getImage(props.data.infographicModifiableRiskFactorsMobile),
		},
	])

    this.infographicFdaSaltRecomendationImg = withArtDirection (
		getImage(props.data.infographicFdaSaltRecomendation), [
		{
			media: "(max-width: 750px)",
			image: getImage(props.data.infographicFdaSaltRecomendationMobile),
		},
	])

    this.calloutAhaGuidelinesImg = withArtDirection (
		getImage(props.data.calloutAhaGuidelines), [
		{
			media: "(max-width: 750px)",
			image: getImage(props.data.calloutAhaGuidelinesMobile),
		},
	])

    this.infographicRatesOfCvEventsImg = withArtDirection (
		getImage(props.data.infographicRatesOfCvEvents), [
		{
			media: "(max-width: 750px)",
			image: getImage(props.data.infographicRatesOfCvEventsMobile),
		},
	])
  }

  componentDidMount() {
    liveRampEventTracking('modifiable-risks-page-view')
  }

  handleHideModal() {
    this.setState({
      showLeavingSiteModal: false,
    })
  }

  handleShowModalClicked() {
    this.setState({
      showLeavingSiteModal: true,
    })
  }

  openLink(link) {
    trackGa('modifiable risks, click,	heart.org redirect -modifiable risks')
    this.setState(() => {
      return { href: link }
    }, this.handleShowModalClicked())
  }

  render() {
    
    return (
        <Layout addedClass='page-risk' location={this.props.location}>
          <div className="risk-page-header" role="img" alt="Cloudy open terrain path with boulders">
            <div className="primary-container">
              <div className="risk-header-text">
                <h1>Modifiable Risk Factors Can Impact Risk of CV Disease</h1>
                <div className="header-comment-wrapper">
                  <p>
                    Research has demonstrated an increased prevalence of certain comorbid conditions, including
                    cardiovascular (CV) and cardiometabolic conditions, in patients with narcolepsy compared with matched
                    controls.<sup>1-3</sup> Certain risk factors for developing CV conditions can be modified by
                    maintaining a healthy lifestyle.<sup>4-13</sup>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="background-white">
            <section className="section-gradient" />
          </div>
          <div className="primary-container">
            <div className="info-section-title">
              <h2>
                Most Common Modifiable Risk Factors for Cardiovascular Disease<sup>4-14</sup>
              </h2>
            </div>
            <div className="modifiable-info-section">
                <GatsbyImage image={ this.infographicModifiableRiskFactorsImg } className='risk-factors' objectFit="contain"  alt='display of 4 connected hexagons with icons representing common modifiable risk factors'></GatsbyImage>
            </div>
          </div>
          <div className="excessive-sodium-info-section">
            <div className="primary-container">
              <div className="sodium-content-graph-wrapper">
                <div className="sodium-content-wrapper">
                  <h3 className="info-section-header">
                    Excessive sodium intake is a modifiable risk factor for CV disease<sup>5</sup>
                  </h3>
                  <p className="sodium-info-content">
                    There is consensus among health organizations, including the American College of Cardiology (ACC) and
                    the American Heart Association (AHA),<sup>4,15</sup> that excess sodium intake is associated with
                    increased BP,<sup>16,17</sup> a strong risk factor for CV disease in the general population.
                    <sup>18-20</sup> Increased sodium intake is also believed to have a direct effect on negative CV
                    outcomes, including coronary heart disease, left ventricular hypertrophy, and stroke.<sup>21-24</sup>
                  </p>
                  <p className="sodium-info-content">
                    According to the Centers for Disease Control and Prevention (CDC), analysis of data from the National
                    Health and Nutrition Examination Survey (NHANES; 2009-2012), in about 9 of 10 American adults (aged
                    ≥19 years), sodium intake exceeded the recommended upper intake limit of 2300 mg/day.<sup>5,25</sup>
                  </p>
                </div>
                    <div className="daily-sodium-intake-container">
                        <StaticImage src={'../../../assets/images/callout-daily-sodium-intake.png'} width={602} objectFit='contain' quality={90} className='daily-sodium-intake-container__img' alt="outline drawing representing 9 out of 10 people" />
                    </div>
                </div>
                <div className="sodium-content-graph-wrapper interactive-infographic">
                    <StaticImage src={'../../../assets/images/thumbnails/home-carousel-slide-1.png'} width={889} objectFit='contain' quality={90} className='interactive-infographic__img' alt="interactive infographic image" />
                    <div className="sodium-content-wrapper centered-content interactive-infographic__content">
                        <div className="interactive-infographic__centered-border">
                            <h3 className='info-section-header'>Chronic elevated dietary sodium can affect the heart, brain, kidneys, and other body systems<sup>26,27</sup></h3>
                            <SiteLink to={'/comorbidities-risk/high-sodium-intake/'} className="read-next-button gtm-click" tracking=''>
                                <div className="read-next-content">
                                    <p><b>LEARN MORE:&nbsp;</b><br className='mobile'/>Interactive Infographic<FontAwesomeIcon className='mobile' icon={faAngleRight}/></p><FontAwesomeIcon className='desktop' icon={faAngleRight}/>
                                </div>
                            </SiteLink>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div className="primary-container">
              <div className="sodium-content-graph-wrapper sodium-content-graph-wrapper--bar-graph">
                  <div className="sodium-graph-wrapper">
                    <StaticImage src={'../../../assets/images/chart-daily-sodium-intake.png'} width={372} objectFit='contain' quality={90} className='sodium-chart' alt="3 bar graphs listing daily sodium intake" />
                  </div>
                  <div className="sodium-content-wrapper centered-content">
                  <p className="sodium-info-content">
                      According to an analysis of data from the 2013-2014 dietary intake portion of the NHANES, the mean dietary sodium intake for Americans ≥2 years was 3409
                      mg/day—which far exceeds the 1500-mg/day ideal target endorsed by the AHA.<sup>6,28</sup>
                  </p>
                  <p className="sodium-info-content">
                      To learn more about the AHA recommendation, go to <a className='link' onClick={() => this.openLink("https://www.heart.org/en/healthy-living/healthy-eating/eat-smart/sodium/how-much-sodium-should-i-eat-per-day")}>heart.org</a>.
                  </p>
                  </div>
              </div>
              <div className="us-dephhs-info">
                  <p>
                  *The US Department of Health and Human Services (USDHHS) recommends a sodium upper intake limit of 2300 mg/day.
                  <sup>25</sup>
                  </p>
                  <p>
                  <sup>†</sup>Based on 2009-2012 NHANES data from 14,728 participants aged ≥2 years.<sup>5</sup>
                  </p>
              </div>
            <div className="reduced-sodium-info-section">
              <div className="blue-info-section">
                <p className="blue-info-content">
                  In addition to daily intake from foods and drinks, sodium in both over-the-counter and prescription medications, including certain narcolepsy medications, can contribute to patients’ total sodium intake.<sup>28,29</sup>
                </p>
              </div>
              <div className="sodium-content-graph-wrapper fda-recommendation">
                  <div className="sodium-graph-wrapper ">
                    <GatsbyImage image={ this.infographicFdaSaltRecomendationImg } className="fda-recommendation__img" objectFit="contain"  alt='FDA recommends 12% reduction in sodium over 2.5 years'></GatsbyImage>
                  </div>
                  <div className="sodium-content-wrapper centered-content">
                    <p className="sodium-info-content">
                        The US Food and Drug Administration (FDA), recognizing that most Americans, irrespective of comorbidities, consume excessive sodium, has recently set targets to decrease sodium intake from commercially packaged, processed, and prepared foods to reduce the risk of developing CV disease.<sup>30,</sup>*
                    </p>
                  </div>
              </div>
              <div className="fda-recommendation-foot-note">
                  <p className="foot-note-text">
                  *The FDA’s voluntary guidance for industry related to commercially packaged, processed, and prepared foods includes the short-term goal of helping Americans to reduce their average sodium intake by 12% (from 3400 mg to 3000 mg of sodium per day) over the next 2.5 years and plans for further iterative reductions in the future.
                  <sup>30</sup>
                  </p>
              </div>
              <div id="HighSodiumIntake" className="reduced-sodium-content-wrapper">
                <div className="sodium-content-wrapper flex-70">
                  <h3 className="info-section-header">
                    Significant reductions in sodium may reduce the risk of developing CV conditions in the general
                    population<sup>31</sup>
                  </h3>
                  <p className="sodium-info-content">
                    In 2019, the National Academies of Sciences, Engineering, and Medicine reported 2300 mg/day as the
                    chronic disease risk reduction intake level for sodium in people aged 14 years and older—ie, the level
                    above which intake reduction is expected to reduce chronic disease risk within an apparently healthy
                    population.<sup>32</sup> Furthermore, a consensus of 11 professional organizations* recommends a goal
                    of at least a 1000-mg/day reduction of dietary sodium intake in most adults as one of the best proven
                    nonpharmacological interventions for prevention and treatment of hypertension.<sup>4</sup> 2019 ACC/AHA guidelines reiterate this recommendation.<sup>33,†</sup>
                  </p>
                  <p className="foot-note-text">
                    <span className="break-word">*2017 ACC/AHA/AAPA/ABC/ACPM/AGS/APhA/ASH/ASPC/NMA/PCNA</span> guideline for
                    the prevention, detection, evaluation, and management of high blood pressure in adults: a report of the
                    American College of Cardiology/American Heart Association Task Force on Clinical Practice Guidelines.
                    <sup>4</sup>
                  </p>
                  <p className="foot-note-text">
                    <sup>†</sup>2019 ACC/AHA guideline on the primary prevention of cardiovascular disease: a report of the
                    American College of Cardiology/American Heart Association Task Force on Clinical Practice Guidelines.
                    <sup>33</sup>
                  </p>
                </div>
                <div className="sodium-graph-wrapper reduction-icon">
                    <GatsbyImage image={ this.calloutAhaGuidelinesImg }  objectFit="contain"  alt='The American College of Cardiology and the American Heart Association recommend a dietary sodium reduction of at least 1000 mg/day for most adults. 4,33'></GatsbyImage>
                </div>
              </div>
            </div>
            </div>
            <div className="sodium-excretion-section">
                <div className="primary-container">
                    <div className="sodium-excretion-section__heading">
                        <div className="sodium-excretion-section__description">
                            <h3 className='info-section-header'>Sodium excretion and CV risk</h3>
                            <p className='sodium-info-content'>A meta-analysis of 10,709 participants from 6 studies showed that higher 24-hour urinary sodium excretion was associated with higher CV risk in analyses that controlled for confounding factors<sup>9</sup></p>
                        </div>
                        <div className="sodium-excretion-section__callout">
                            <h4 className='info-section-header'>Each <br/><span className="highlight">1000-mg increase<br className='mobile-only'/> in 24-hour<br className='mobile-only'/></span> urinary sodium excretion is associated with an 18% increase in CV risk<sup>9,*</sup></h4>
                        </div>
                    </div>
                    <div className="sodium-excretion-section__img-container">
                        <GatsbyImage image={ this.infographicRatesOfCvEventsImg } objectFit={'contain'} className="sodium-excretion-section__graph" alt='Rates of CV events based on amount of urinary sodium excretion (N=10,709) chart'></GatsbyImage>
                    </div>
                    <p className="foot-note-text">
                        *A meta-analysis of individual-participant data from 6 prospective cohorts of generally healthy adults. Sodium excretion was assessed with the use of at least two 24-hour urine samples per participant. The primary outcome measure was a cardiovascular event, including coronary revascularization or fatal or nonfatal myocardial infarction or stroke. Hazard ratios were estimated from models adjusted for potential confounding factors, including age, sex, race, educational level, height, body-mass index, smoking status, alcohol consumption, physical activity, history of diabetes and elevated cholesterol status, family history of CV disease, 24-hour urinary potassium excretion, and total energy intake and modified Dietary Approaches to Stop Hypertension diet quality score.<sup>9</sup>
                    </p>
                </div>
            </div>
            <div className="general-population-section">
                <div className="primary-container">
                    <h3 className='info-section-header'>Significant reductions in sodium may reduce the risk of developing CV conditions in the general population<sup>31</sup></h3>
                    <p className='sodium-info-content'>A coronary heart disease policy model<sup>†</sup> projected that a US population–wide reduction in dietary sodium intake of 1200 mg/day can reduce the annual number of new cases of coronary heart disease, myocardial infarction, stroke, and any-cause deaths.<sup>31</sup></p>
                    <p className="foot-note-text">
                        <sup>†</sup>The Coronary Heart Disease Policy Model, a computer simulation of heart disease in US adults aged 35-84 years.<sup>31</sup>
                    </p>
                    <SiteLink to={'/comorbidities-risk/high-sodium-intake/'} className="read-next-button gtm-click" tracking='modifiable risks, click,	management considerations - modifiable risks'>
                        <div className="read-next-content">
                            <p><b>READ NEXT:</b><br className='mobile'/> Learn more about chronic elevated<br className='mobile'/> dietary sodium<FontAwesomeIcon  className='mobile' icon={faAngleRight}/></p><FontAwesomeIcon  className='desktop' icon={faAngleRight}/>
                        </div>
                    </SiteLink>
                </div>
            </div>
          <ReferenceComponent referenceList={references} />
          <LeavingSiteModal
            isShowModal={this.state.showLeavingSiteModal}
            onClose={this.handleHideModal}
            href={this.state.href}
            tracking={'modifiable risks, click, heart redirect - modifiable risks'}
            cancelTracking={'modifiable risks, click, cancel heart redirect - modifiable risks'}
            exitTracking={'modifiable risks, click, heartorg exit overlay views - modifiable risks'}
            liveRampTrackingString={'heartorg-leaving-site-continue-click'}
            location={this.props.location}
          />
        </Layout>
      )
  }
}

export default Risk

export const pageQuery = graphql`
query risksPageQuery {
    infographicModifiableRiskFactors: file(relativePath: {eq: "infographics/infographic-modifiable-risk-factors.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 910
                quality: 97
            )
        }
    },
    infographicModifiableRiskFactorsMobile: file(relativePath: {eq: "infographics/infographic-modifiable-risk-factors-mobile.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 288
                quality: 90
            )
        }
    },
    infographicFdaSaltRecomendation: file(relativePath: {eq: "infographics/infographic-fda-salt-recomendation.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 410
                quality: 90
            )
        }
    },
    infographicFdaSaltRecomendationMobile: file(relativePath: {eq: "infographics/infographic-fda-salt-recomendation-mobile.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 258
                quality: 90
            )
        }
    },
    calloutAhaGuidelines: file(relativePath: {eq: "callout-aha-guidelines.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 350
                quality: 90
            )
        }
    },
    calloutAhaGuidelinesMobile: file(relativePath: {eq: "callout-aha-guidelines-mobile.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 293
                quality: 90
            )
        }
    },
    infographicRatesOfCvEvents: file(relativePath: {eq: "infographics/infographic-rates-of-cv-events.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 1056
                quality: 90
            )
        }
    },
    infographicRatesOfCvEventsMobile: file(relativePath: {eq: "infographics/infographic-rates-of-cv-events-mobile.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 295
                quality: 90
            )
        }
    },
}
`